import React from 'react';
import {graphql} from 'gatsby';
import {PageContent} from '../components/organisms/Layout';
import {DynamicZone, Header, Intro, SEO} from '../components/organisms';

const Project = ({data}) => {
  const {
    strapi: {
      project: {
        title,
        image,
        achievement,
        content,
        SEO: _SEO,
        headerVariant = 'S'
      }
    }
  } = data;
  return (
    <PageContent>
      <SEO
        title={_SEO?.title}
        description={_SEO?.description}
        meta={[
          {
            name: 'keywords',
            content: _SEO?.keywords,
          },
          {
            property: 'og:image',
            content: `${process.env.GATSBY_CLIENT_ORIGIN}${image?.file?.publicURL}`
          }
        ]}
      />
      <Header header={{
        tag: 'Project',
        text: title,
        media: {
          file: image,
        },
        showWave: true,
        waveColor: 'lightBlue',
        variant: headerVariant,
      }}/>
      <Intro intro={{
        startingColumn: 2,
        text: {
          title: 'Achievement',
          body: achievement,
        },
      }}/>
      <DynamicZone blocks={content}/>
    </PageContent>
  );
};

export default Project;

export const projectQuery = graphql`
  query GET_PROJECT($id: ID!) {
    strapi {
      project(id: $id) {
        headerVariant
        SEO {
          ...SEO
        }
        title
        image {
          ...MediaFile
        }
        achievement
        content {
          __typename
          ...Carousel
          ...ContactUs
          ...ContactPersonBlock
          ...MediaWithText
          ...MediaBlock
          ...PlainText
          ...Share
          ...Testimonial
          ...USPBlock
          ...Download
        }
      }
    }
  }
`;
